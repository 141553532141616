import Button from '@dustin-web/microsite-components/src/button'
import React from 'react'

export type LinkButtonBlockProps = {
  block: boolean
  link: { text: string; url: string; target: string }
  primary: boolean
}

export const LinkButtonBlock = (props: LinkButtonBlockProps) => {
  const { link, primary, block } = props
  const { url, text } = link

  return (
    <Button
      primary={primary}
      href={url}
      text={text}
      link={true}
      block={block}
      icon="arrow-right"
      // style={text === 'Vårt erbjudande' ? { borderColor: 'white' } : {}}
    />
  )
}
